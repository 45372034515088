import React from 'react';
import Layout from '../layouts/index';

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout>
        <h1>404 Not Found</h1>
        <p>Det finns inget på denna adress. Kanske kan dessa sidor innehålla vad du söker?</p>
      </Layout>
    );
  }
}

export default NotFoundPage;
